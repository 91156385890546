import React, { useContext, useEffect, useState } from 'react';

import * as S from './styles';
import CardData from './CardData';
import PersonalData from './PersonalData';
import PurchaseDetails from './PurchaseDetails';
import {
  convertDateDefault,
  findValueInArray,
  getLastUserInfo,
  maskCnpj,
  maskCPF,
  trackEventMatomo,
  trackEventMatomoVisit,
} from 'js/library/utils/helpers';
import submitLeadPayment from 'js/library/utils/API/payment/apiSubmitLeadPayment';
import checkProduct from 'js/library/utils/API/payment/apiCheckProduct';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SignUpContext } from 'js/context/SignUpContext';
import { getUserPartner } from 'js/library/utils/API/apiGetUserPartner';
import phone from 'phone';
import phoneMaskArray from 'js/components/LoginForms/phoneMaskArray.json';
import PixQrCode from './PixQrCode';
import createLead from 'js/library/utils/API/payment/apiCreateLead';

//pagamento/?productId=982570

export default function PaymentPage(props) {
  const [currentComponent, setCurrentComponent] = useState('purchaseDetails');
  const [productData, setProductData] = useState({
    productType: '',
    name: '',
    price: '',
    description: '',
    productImage: { src: '', alt: '' },
    id: null,
    quantity: 0,
  });

  const { t } = useTranslation();
  const userInfo = getLastUserInfo();
  let uId = null;

  const { formFields } = useContext(SignUpContext);
  const seuClubePersist = JSON.parse(sessionStorage.getItem('seuClubeInfos'));
  const partnerId = `${seuClubePersist.id}_${seuClubePersist.clubeId}`;

  let userCellPhone = null;
  let userEmail = '';

  if (!userInfo.triiboId.includes('@anonymous')) {
    uId = userInfo.uId;

    try {
      userCellPhone = userInfo.contactList.find((i) => i.type === 'cellPhone').value;
    } catch {}
    try {
      userEmail = userInfo.contactList.find((i) => i.type === 'email').value;
    } catch {}
  }

  const [leadInfos, setLeadInfos] = useState(null);

  const [loading, setLoading] = useState(false);
  const [loadingInfos, setLoadingInfos] = useState(true);

  const navigate = useNavigate();

  const [data, setData] = useState({
    cardData: {
      holder: '',
      brand: '',
      cardNumber: '',
      expirationDate: '',
    },
    addressData: {
      address_1: '',
      address_2: '',
      city: '',
      state: '',
      postcode: '',
      country: 'Brasil',
      neighborhood: '',
      number: '',
    },
    firstName: '',
    lastName: '',
    fone: userCellPhone,
    email: userEmail,
    confirm_email: userEmail,
  });

  useEffect(() => {
    let data = {};

    if (formFields.length > 0) {
      getUserPartner(partnerId, userInfo.uId).then((userInfo) => {
        formFields.forEach((field, index) => {
          switch (field?.type) {
            case 'root':
              if (field.fieldType === 'checkbox') {
                data = {
                  ...data,
                  [field.fieldName]: userInfo[field.fieldName] ? userInfo[field.fieldName] : false,
                };
              } else if (field.fieldType === 'date') {
                const date = new Date(userInfo[field.fieldName]);

                data = {
                  ...data,
                  [field.fieldName]: convertDateDefault(date),
                };
              } else {
                data = {
                  ...data,
                  [field.fieldName]: userInfo[field.fieldName] ? userInfo[field.fieldName] : '',
                };
              }
              break;

            case 'addressList':
              if (userInfo?.addressList?.length > 0) {
                const address = userInfo?.addressList[0];

                address
                  ? (data = { ...data, addressList: { [field.fieldName]: address } })
                  : (data = { ...data, addressList: { [field.fieldName]: {} } });
              }
              break;

            case 'contactList':
              if (field.fieldType === 'email') {
                if (findValueInArray(userInfo.contactList, 'type', field.fieldName)) {
                  const dataEmail = findValueInArray(
                    userInfo.contactList,
                    'type',
                    field.fieldName
                  ).value;
                  data = {
                    ...data,
                    contactList: { ...data.contactList, [field.fieldName]: dataEmail },
                  };
                } else {
                  data = {
                    ...data,
                    contactList: { ...data.contactList, [field.fieldName]: '' },
                  };
                }
              } else if (field.fieldType === 'cellPhone') {
                if (findValueInArray(userInfo.contactList, 'type', field.fieldType)) {
                  const dataCellPhone = phone(
                    findValueInArray(userInfo.contactList, 'type', field.fieldType).value
                  );

                  const maskFilter = phoneMaskArray.filter(
                    (mask) => mask.ddd === dataCellPhone.countryCode
                  );

                  data = {
                    ...data,
                    contactList: {
                      ...data.contactList,
                      [field.fieldName]: {
                        mask: { ...maskFilter[0] },
                        phone: dataCellPhone.phoneNumber.split(maskFilter[0].ddd)[1],
                      },
                    },
                  };
                }
              } else {
                data = {
                  ...data,
                  contactList: {
                    ...data.contactList,
                    [field.fieldName]: findValueInArray(
                      userInfo.contactList,
                      'type',
                      field.fieldName
                    ).value,
                  },
                };
              }
              break;

            case 'documentList':
              if (field.fieldType === 'cpf') {
                if (findValueInArray(userInfo.documentList, 'type', field.fieldName)) {
                  data = {
                    ...data,
                    documentList: {
                      ...data.documentList,
                      [field.fieldName]: maskCPF(
                        findValueInArray(userInfo.documentList, 'type', field.fieldName).value
                      ),
                    },
                  };
                }
              } else if (field.fieldType === 'cnpj') {
                if (findValueInArray(userInfo.documentList, 'type', field.fieldName)) {
                  data = {
                    ...data,
                    documentList: {
                      ...data.documentList,
                      [field.fieldName]: maskCnpj(
                        findValueInArray(userInfo.documentList, 'type', field.fieldName).value
                      ),
                    },
                  };
                }
              } else {
                data = {
                  ...data,
                  documentList: {
                    ...data.documentList,
                    [field?.fieldName]: findValueInArray(
                      userInfo?.documentList,
                      'type',
                      field?.fieldType
                    )?.value,
                  },
                };
              }
              break;

            case 'optInList': {
              const optIn = `optIn_${field.optInId !== undefined ? field.optInId : ''}`;
              data = {
                ...data,
                optInList: {
                  ...data.optInList,
                  [optIn]: {
                    accept: findValueInArray(userInfo.optInList, 'optInId', field.optInId)
                      ? findValueInArray(userInfo.optInList, 'optInId', field.optInId).accept
                      : false,
                  },
                },
              };
              break;
            }

            case 'others':
              if (field.inputType === 'date') {
                const date = new Date(userInfo[field.fieldName]);
                data = {
                  ...data,
                  [field.fieldName]: convertDateDefault(date),
                };
              } else if (field.inputType === 'checkbox') {
                data = {
                  ...data,
                  [field.fieldName]: { accept: userInfo[field.fieldName] },
                };
              } else {
                data = {
                  ...data,
                  [field.fieldName]: userInfo[field.fieldName],
                };
              }
              break;

            default:
              break;
          }
          if (index === formFields.length - 1) {
            setData((prevState) => ({
              ...prevState,
              firstName: data.firstName || data.firstname,
              lastName: data.lastName || data.lastname,
              addressData: {
                ...prevState.addressData,
                address_1: data.addressList?.endereco.streetAve,
                address_2: data.addressList?.endereco.extra,
                city: data.addressList?.endereco.city,
                state: data.addressList?.endereco.state,
                postcode: data.addressList?.endereco.zipCode,
                neighborhood: data.addressList?.endereco.neighborhood,
                number: data.addressList?.endereco.streetNumber,
              },
            }));
          }
        });
      });
    }
  }, [formFields, formFields.length, partnerId, userInfo.uId]);

  useEffect(() => {
    trackEventMatomoVisit('Pagamento');
    document.title = 'Pagamento';
  }, []);

  const ConditionalRendering = () => {
    const renderComponent = {
      purchaseDetails: (
        <PurchaseDetails productData={productData} handleLoggedCheckout={handleLoggedCheckout} />
      ),
      cardData: (
        <CardData
          setData={setData}
          data={data}
          setCurrentComponent={setCurrentComponent}
          handleSubmit={handleSubmit}
          loading={loading}
          productData={productData}
        />
      ),
      personalData: (
        <PersonalData
          leadInfos={leadInfos}
          setData={setData}
          data={data}
          setCurrentComponent={setCurrentComponent}
          productData={productData}
          setLeadInfos={setLeadInfos}
        />
      ),
      pixQrCode: (
        <PixQrCode
          leadInfos={leadInfos}
          uId={uId}
          setData={setData}
          data={data}
          setCurrentComponent={setCurrentComponent}
          productData={productData}
          setLeadInfos={setLeadInfos}
        />
      ),
    };

    return renderComponent[currentComponent];
  };

  const handleLoggedCheckout = () => {
    try {
      if (userInfo.triiboId.includes('@anonymous')) {
        props.setOpenState(true);
      } else if (userInfo.userProfile) {
        toast.error(t('checkout.has_subscription'));
      } else {
        setCurrentComponent('personalData');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let lead = leadInfos;

    try {
      if (lead.id) {
        const expirationDateMonth = data.cardData.expirationDate.split('/')[0];
        const expirationDateYear = '20' + data.cardData.expirationDate.split('/')[1];

        const expirationDate = expirationDateMonth + '/' + expirationDateYear;

        const submitPayment = await submitLeadPayment(
          uId,
          lead.id,
          data.cardData.holder,
          data.cardData.brand,
          data.cardData.cardNumber.replace(/\D/g, '').slice(0, 16),
          expirationDate
        );

        const paymentStatus = submitPayment.payment.status;

        localStorage.setItem('paymentStatus', paymentStatus);

        if (paymentStatus === 1 || paymentStatus === 2 || paymentStatus === 20) {
          // Se o status for "Authorized", "PaymentConfirmed" ou "Scheduled"
          setLoading(false);
          if (userInfo.triiboId.includes('@anonymous')) {
            sessionStorage.setItem('@user-payment-email', data.email);
            // setOpenModalResgisterUser(true);
          } else {
            const subscriptionProfile = sessionStorage.getItem('subscriptionProfile');

            if (subscriptionProfile) {
              let tempUserInfo = userInfo;
              tempUserInfo.userProfile = subscriptionProfile;

              localStorage.setItem(
                'userInfoAuxiliar',
                JSON.stringify({
                  ...tempUserInfo,
                })
              );
            }
            navigate('/pagamento-confirmado');
            sessionStorage.removeItem('leadId');
            trackEventMatomo('Pagamento', 'click', 'button', 'Efetuar pagamento');
          }
        } else {
          setLoading(false);
          if (paymentStatus === 3) {
            // Pagamento recusado pelo cartão
            toast.error(t('checkout.payment_refused'));
          } else {
            // Outro erro, possível erro interno
            toast.error(t('checkout.payment_error'));
          }
        }
      }
    } catch (error) {
      console.error('error', error);
      setLoading(false);
      toast.error(t('checkout.payment_error'));
    }
  };

  useEffect(() => {
    sessionStorage.removeItem('@user-payment-email');
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const productId = urlParams.get('productId');

    if (!productId) {
      toast.error(t('checkout.unidentified_payment'));
      return;
    }

    const userInfo = getLastUserInfo();
    let uId = null;

    if (!userInfo.triiboId.includes('@anonymous')) {
      uId = userInfo.uId;
    }

    //removendo dado de assinatura local para ter jornada limpa
    sessionStorage.removeItem('subscriptionProfile');

    checkProduct(uId, productId)
      .then((res) => {
        let productType;

        const metaDataArray = res.response.meta_data;

        const subscriptionData = {
          subscription_period: '',
          subscription_period_interval: '',
          subscription_length: '',
        };

        if (res.response.type === 'subscription') {
          if (res.response.profile) {
            sessionStorage.setItem('subscriptionProfile', res.response.profile);
          }
          productType = 'subscription';

          metaDataArray.forEach((item) => {
            if (item.key === '_subscription_period') {
              subscriptionData.subscription_period = item.value;
            } else if (item.key === '_subscription_period_interval') {
              subscriptionData.subscription_period_interval = item.value;
            } else if (item.key === '_subscription_length') {
              subscriptionData.subscription_length = item.value;
            }
          });
        } else if (res.response.virtual) {
          productType = 'digital';
        } else {
          productType = 'physical';
        }

        setProductData({
          subscriptionData: subscriptionData,
          name: res.response.name,
          description: res.response.description,
          price: res.response.price,
          productImage: res.response.images.length > 0 ? res.response.images[0] : '',
          productType: productType,
          id: productId,
          quantity: 1,
        });
        setLoadingInfos(false);
      })
      .catch((error) => {
        setProductData(null);
        toast.error(t('checkout.couldnt_load_product'));
        setLoadingInfos(false);
      });
  }, [t]);

  if (loadingInfos) {
    return (
      <div
        style={{
          width: '100%',
          height: '50vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size={50} />
      </div>
    );
  }

  return (
    <div className="paper-container">
      <S.Container>
        {productData ? ConditionalRendering() : t('checkout.product_not_found')}
      </S.Container>
    </div>
  );
}
