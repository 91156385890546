import React, { useContext, useEffect, useState } from 'react';
import { SignUpContext } from 'js/context/SignUpContext';

import { Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Modal,
  Button,
  CircularProgress,
  Checkbox,
  Divider,
  IconButton,
} from '@mui/material';
import GoBack from 'js/containers/GoBack.js';

//icones
import alert from 'styles/assets/Messages/Alert.png';
import establishment from 'styles/assets/Messages/Establishment.png';
import news from 'styles/assets/Messages/News.png';
import promotion from 'styles/assets/Messages/Promotion.png';
import triibomania from 'styles/assets/Messages/Triibomania.png';
import provider from 'styles/assets/Messages/Provider.png';
import tribomania from 'styles/assets/Messages/Tribomania.png';

import {
  colorText,
  getLastUserInfo,
  trackEventMatomoElementId,
  isFrameUrl,
  checkAnonymous,
} from 'js/library/utils/helpers';
import {
  convertToDateDefault,
  trackEventMatomoVisit,
  trackEventMatomo,
} from 'js/library/utils/helpers';
import deleteMessage from 'js/library/utils/API/deleteMessage';
import getAllMessagesFromUser from 'js/library/utils/API/getAllMessagesFromUser';
import markAsRead from 'js/library/utils/API/markAsRead';

export default function Inbox() {
  const { infosSeuClube } = useContext(SignUpContext);
  const userInfo = getLastUserInfo();
  const isAnonymous = checkAnonymous(userInfo);

  const [messages, setMessages] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [messageSelected, setMessageSelected] = useState([]);
  const [deletedMessages, setDeletedMessages] = useState([]);

  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    document.title = 'Caixa de entrada';

    window['alert'] = alert;
    window['establishment'] = establishment;
    window['news'] = news;
    window['promotion'] = promotion;
    window['triibomania'] = triibomania;
    window['tribomania'] = tribomania;
    window['provider'] = provider;

    trackEventMatomoVisit('CaixaDeEntrada');

    if (!isAnonymous && messages === null) {
      getAllMessagesFromUser(userInfo.uId, 'all', false)
        .then((result) => {
          setMessages(result);
        })
        .catch((err) => {
          setMessages([]);
        });
    }
  }, [userInfo, isAnonymous, messages]);

  const readMessages = (item) => {
    let auxMessages = messages;
    //informando que a mensagem foi lida para o banco
    if (messages[item].lida === undefined) {
      markAsRead(userInfo.uId, messages[item].tableId, userInfo.triiboId);
      auxMessages[item].lida = new Date().getTime();
    }

    trackEventMatomoElementId('caixaDeEntrada', 'tocar', 'cardMessage', messages[item]?.tableId);
    setMessages(auxMessages);
    setOpenModal(true);
    setMessageSelected(messages[item]);
  };

  const editMessages = (index) => (event) => {
    let auxDeletedMessages = [...deletedMessages];

    if (event.target.checked) {
      auxDeletedMessages.push(index);
    } else {
      const itemIndex = auxDeletedMessages.indexOf(index);
      if (itemIndex !== -1) {
        auxDeletedMessages.splice(itemIndex, 1);
      }
    }
    setDeletedMessages(auxDeletedMessages);
  };

  const deleteMessages = () => {
    let auxMessages = [...messages];
    let auxDeletedMessages = [...deletedMessages];

    //APAGAR MENSAGENS SELECIONADAS
    deletedMessages.map((item, i) => {
      auxDeletedMessages.splice(i, 1);
      auxMessages.splice(item, 1);
      return deleteMessage(userInfo.uId, auxMessages[item].tableId);
    });

    setDeletedMessages([...auxDeletedMessages]);
    setMessages([...auxMessages]);
    trackEventMatomo('caixaDeEntrada', 'tocar', 'button', 'Excluir Mensagens Selecionadas');
  };

  let timeDebounce = null;

  function scrollMatomo() {
    clearTimeout(timeDebounce);
    timeDebounce = setTimeout(() => {
      trackEventMatomo('Caixa de entrada', 'scroll', 'lista', 'lista de mensagens');
    }, 400);
  }

  if (isAnonymous) {
    return <Navigate to="/" />;
  } else {
    return (
      <div className="paper-container">
        <div style={{ display: 'flex' }}>
          <GoBack />
          <Typography
            variant="h6"
            style={{
              fontWeight: 'bolder',
              width: '85%',
              textAlign: 'center',
            }}
          >
            {t('messages.messages')}
          </Typography>
        </div>

        <Divider color="secondary" style={{ margin: '10px 0px' }} />

        <div
          style={{
            height: 'calc(100vh - 260px)',
            flexFlow: 'column nowrap',
            display: 'flex',
          }}
        >
          {messages === null ? (
            <div align="center" className="loading-content">
              <CircularProgress size={30} style={{ color: infosSeuClube.colorPrimary }} />
            </div>
          ) : messages.length === 0 ? (
            <Typography variant="h6" style={{ padding: '30px', color: '#707070' }}>
              <span>{t('messages.you_dont_have_messages')}</span>
            </Typography>
          ) : (
            <List onScroll={() => scrollMatomo()} className="scrollable messagesScroll">
              {messages.map(function (item, i) {
                const icon = [item.tipo];

                return (
                  <div key={item.tableId}>
                    <ListItem
                      style={{ cursor: 'pointer' }}
                      onClick={() => readMessages(i)}
                      alignItems="flex-start"
                    >
                      <ListItemAvatar>
                        <img
                          width="60%"
                          className="img-radius"
                          src={window[icon]}
                          alt="notificacoes"
                        />
                      </ListItemAvatar>

                      <div style={{ width: '60%' }}>
                        <ListItemText
                          key={item.key}
                          primary={
                            <Typography
                              style={
                                item.lida === undefined
                                  ? { fontWeight: 'bolder', color: '#707070' }
                                  : { color: '#707070' }
                              }
                              variant="subtitle2"
                            >
                              {item.titulo}
                            </Typography>
                          }
                          secondary={
                            <span style={{ color: '#707070' }}>
                              {item.mensagem.length > 110
                                ? item.mensagem.slice(0, 109) + '...'
                                : item.mensagem}
                            </span>
                          }
                        />
                      </div>

                      <ListItemSecondaryAction style={{ color: '#707070' }}>
                        <Checkbox
                          style={{ color: '#707070' }}
                          value="checkedA"
                          inputProps={{ 'aria-label': 'Checkbox A' }}
                          onChange={editMessages(i)}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  </div>
                );
              })}
            </List>
          )}

          <Button
            variant="contained"
            style={{
              backgroundColor: infosSeuClube.colorPrimary,
              color: colorText(infosSeuClube.colorPrimary),
              marginTop: 10,
              display: deletedMessages.length === 0 ? 'none' : 'block',
            }}
            onClick={() => deleteMessages()}
          >
            {t('messages.delete_selected')}
          </Button>
        </div>

        {messageSelected !== null ? (
          <Modal
            onClose={() => {
              setOpenModal(false);
              trackEventMatomo('caixaDeEntrada', 'tocar', 'overlayDiv', 'closeMessageModal');
            }}
            open={openModal}
          >
            <Paper
              sx={{
                position: 'absolute',
                padding: '20px',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: { xs: '80%', sm: '70%', md: '50%' },
                maxWidth: '600px',
              }}
            >
              <Grid container>
                <Grid item xs={12} sx={{ textAlign: 'right' }}>
                  <IconButton
                    size="small"
                    style={{ color: 'black' }}
                    onClick={() => {
                      setOpenModal(false);
                      trackEventMatomo('caixaDeEntrada', 'tocar', 'button', 'closeMessageModal');
                    }}
                  >
                    X
                  </IconButton>
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <img
                    style={{
                      width: '50%',
                      maxWidth: 60,
                    }}
                    className="img-radius"
                    src={window[[messageSelected.tipo]]}
                    alt="notificacoes"
                  />
                </Grid>

                <Grid item xs={12} sx={{ textAlign: 'center', marginBottom: '15px' }}>
                  <Typography variant="subtitle2">
                    {convertToDateDefault(messageSelected.data)}
                  </Typography>
                </Grid>
                <Divider />
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
                    {messageSelected.titulo}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" style={{ whiteSpace: 'pre-line' }}>
                    {messageSelected.mensagem}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  {messageSelected.url !== undefined && (
                    <Button
                      onClick={() => {
                        if (isFrameUrl(messageSelected.url)) {
                          const urlRedirect = new URL(messageSelected.url);
                          navigate(urlRedirect.pathname + urlRedirect.search);
                        } else {
                          window.open(messageSelected.url, 'new');
                        }
                      }}
                      fullWidth={true}
                      style={{
                        textTransform: 'capitalize',
                        backgroundColor: infosSeuClube.colorPrimary,
                        color: colorText(infosSeuClube.colorPrimary),
                      }}
                      className="delete-button"
                      variant="contained"
                    >
                      <span style={{ fontWeight: '550', color: 'white' }}>
                        {t('messages.show_details')}
                      </span>
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Modal>
        ) : null}
      </div>
    );
  }
}
