import React, { createContext, useEffect, useState } from 'react';
import { getPartnerUserForms } from 'js/library/utils/API/apiGetPartnerUserForms';
import { useDispatch } from 'react-redux';
import { createUserPartner } from 'js/library/utils/API/apiCreateUserPartner';
import { login } from 'js/library/utils/API/apiLogin';
import { loginUser } from 'js/library/services/AuthenticationManager';
import { getUserPartner } from 'js/library/utils/API/apiGetUserPartner';
import { getClubIdEnvironment, getLastUserInfo } from 'js/library/utils/helpers';
import { getSeuClube } from 'js/library/utils/API/seuClube';
import { linkAccount } from 'js/library/utils/API/linkAccount';
import cfac22 from 'js/library/utils/cfac22/cfac22';

export const SignUpContext = createContext('');

function SignUpProvider({ children }) {
  const [cellPhone, setCellPhone] = useState('');
  const [formFields, setFormFields] = useState([]);
  const [validateInfos, setValidateInfos] = useState(null);
  const [accessToken, setAccessToken] = useState('');
  const [isUserValidated, setIsUserValidated] = useState(false);
  const [sendCodeType, setSendCodeType] = useState({
    email: false,
    sms: true,
    whatsapp: false,
  });
  const [formData, setFormData] = useState({});
  const [authSmsInfos, setAuthSmsInfos] = useState({});
  const [checkUser, setCheckUser] = useState(false);
  const [pathRedirect, setPathRedirect] = useState('');
  const [infosSeuClube, setInfosSeuClube] = useState(null);
  const [points, setPoints] = React.useState(0);
  const clubeId = getClubIdEnvironment();

  const dispatch = useDispatch();
  const userInfo = getLastUserInfo();

  const seuClubePersist = JSON.parse(sessionStorage.getItem('seuClubeInfos'));
  const userAnonymous = JSON.parse(localStorage.getItem('userInfoAuxiliar'));

  // const signUp = () => {
  //   createUserPartner(true, formData)
  //     .then(async (res) => {
  //       //linkando usuário com anonimo
  //       linkAccount(res.userInfo.uId, userAnonymous.triiboId, res.userInfo.triiboId);

  //       //logando usuário no firebase
  //       sessionStorage.removeItem('userInfoAuxiliar');
  //       await loginUser(res.userInfo.triiboId.replace(/[,]/gi, '.'), res.userInfo.passPhrase);

  //       dispatch({
  //         type: 'USER_FORM_FULFILLED',
  //         payload: {
  //           cellPhone: cellPhone,
  //           userInfo: {
  //             ...res.userInfo,
  //             triiboId: res.userInfo.triiboId,
  //             uId: res.userInfo.uId,
  //           },
  //         },
  //       });

  //       if (
  //         sessionStorage.getItem('loginOption') === '3' &&
  //         sessionStorage.getItem('loginRedirect') !== undefined
  //       ) {
  //         setPathRedirect(sessionStorage.getItem('loginRedirect').split(origin)[1]);
  //         sessionStorage.removeItem('loginOption');
  //         sessionStorage.removeItem('loginRedirect');
  //       } else {
  //         setPathRedirect('/');
  //       }

  //       setCheckUser(true);
  //     })
  //     .catch((error) => {
  //       dispatch({
  //         type: 'USER_FORM_REJECTED',
  //         payload: error,
  //       });
  //     });
  // };

  const validateUser = () => {
    setIsUserValidated(true);
  };

  const signUp = () => {
    login(cellPhone, userInfo.triiboId)
      .then((response) => {
        createUserPartner(response.userInfo.user.uId, true, formData)
          .then(async (res) => {
            //logando usuário no firebase
            localStorage.removeItem('userInfoAuxiliar');
            await loginUser(
              response.userInfo.user.triiboId.replace(/[,]/gi, '.'),
              response.userInfo.user.passPhrase
            );

            dispatch({
              type: 'USER_FORM_FULFILLED',
              payload: {
                cellPhone: cellPhone,
                userInfo: {
                  ...res.userInfo,
                  triiboId: response.userInfo.user.triiboId,
                  uId: response.userInfo.user.uId,
                },
              },
            });

            if (
              sessionStorage.getItem('loginOption') === '3' &&
              sessionStorage.getItem('loginRedirect') !== undefined
            ) {
              setPathRedirect(sessionStorage.getItem('loginRedirect'));
              sessionStorage.removeItem('loginOption');
              sessionStorage.removeItem('loginRedirect');
            } else {
              setPathRedirect('/');
            }

            setCheckUser(true);
          })
          .catch((error) => {
            dispatch({
              type: 'USER_FORM_REJECTED',
              payload: error,
            });
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const signIn = (validateInfos) => {
    getUserPartner(seuClubePersist.partnerId, validateInfos.uId)
      .then(async (res) => {
        //linkando usuário com anonimo
        linkAccount(validateInfos.uId, userAnonymous.triiboId, res.triiboId);

        //logando usuário no firebase
        localStorage.removeItem('userInfoAuxiliar');
        await loginUser(res.triiboId.replace(/[,]/gi, '.'), res.passPhrase);

        dispatch({
          type: 'CODE_INPUT_FULFILLED',
          payload: {
            cellPhone: cellPhone,
            userInfo: {
              ...res,
              triiboId: res.triiboId,
              uId: validateInfos.uId,
            },
          },
        });

        setPathRedirect(sessionStorage.getItem('loginRedirect'));

        if (
          sessionStorage.getItem('loginOption') === '3' &&
          sessionStorage.getItem('loginRedirect') !== undefined
        ) {
          setPathRedirect(sessionStorage.getItem('loginRedirect'));
          sessionStorage.removeItem('loginOption');
          sessionStorage.removeItem('loginRedirect');
        } else {
          setPathRedirect('/');
        }
        setCheckUser(true);
      })
      .catch((error) => {
        dispatch({
          type: 'CODE_INPUT_REJECTED',
          payload: error,
        });
      });
  };

  useEffect(() => {
    getSeuClube(clubeId).then(async (response) => {
      setInfosSeuClube(response);
      await getPartnerUserForms(response.partnerId)
        .then((result) => {
          setFormFields(result.formFields);
        })
        .catch((error) => {
          setPathRedirect('/registration-unavailable');
        });

      const dynamicManifest = {
        name: response.name,
        short_name: response.name,
        start_url: document.location.origin,
        display: 'standalone',
        background_color: '#ffffff',
        theme_color: '#000000',
        icons: [
          {
            src: cfac22('STORAGE_URL') + 'seuClube%2F' + response.favicon + '?alt=media',
            sizes: '64x64 32x32 24x24 16x16',
            type: 'image/x-icon',
          },
          {
            src: cfac22('STORAGE_URL') + 'seuClube%2F' + response.favicon + '?alt=media',
            type: 'image/png',
            sizes: '192x192',
          },
          {
            src: cfac22('STORAGE_URL') + 'seuClube%2F' + response.favicon + '?alt=media',
            type: 'image/png',
            sizes: '512x512',
          },
        ],
      };

      const blob = new Blob([JSON.stringify(dynamicManifest)], { type: 'application/json' });
      const manifestURL = URL.createObjectURL(blob);

      const link = document.createElement('link');
      link.rel = 'manifest';
      link.href = manifestURL;
      document.head.appendChild(link);

      return () => {
        URL.revokeObjectURL(manifestURL);
        document.head.removeChild(link);
      };
    });
  }, [clubeId]);

  return (
    <SignUpContext.Provider
      value={{
        setCellPhone,
        setFormFields,
        setAccessToken,
        setSendCodeType,
        setValidateInfos,
        setFormData,
        setAuthSmsInfos,
        signUp,
        signIn,
        setCheckUser,
        setPoints,
        isUserValidated,
        validateUser,
        setIsUserValidated,
        sendCodeType,
        cellPhone,
        formFields,
        accessToken,
        validateInfos,
        formData,
        authSmsInfos,
        checkUser,
        pathRedirect,
        infosSeuClube,
        points,
      }}
    >
      {children}
    </SignUpContext.Provider>
  );
}

export default SignUpProvider;

